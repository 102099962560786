import { DateTime } from "luxon";
import { DrawData } from "../components/map/libreMap";

export type Plot = {
  id: string;
  name: string;
  hedgeRows: Hedgerow[];
  submissionInfo?: SubmissionInfo;
};

export type SubmissionInfo = {
  ownerEmailAddress: string;
  submitDate: DateTime;
  submitEmailAddress: string;
}

export type Hedgerow = {
  id: string;
  lastUpdated: DateTime;
  name: string;
  age: HedgerowAge;
  condition: HedgerowCondition;
  style: HedgerowStyle;
  fertiliser: HedgerowFertiliser;
  plantTypes: string[];
  height: HedgerowHeight;
  width: HedgerowWidth;
  baseCanopyMetres: number;
  treeTypes: string[];
  notes?: string;
  //Map specifics
  coords: Coords[];
  areaMetreSquared: number;
  state: HedgerowState; //State is removed when submitting to CC
};

export type HedgerowState = {
  mapDrawData?: DrawData;
  mapId?: string;
  showInvalidIndicator: boolean;
}

export type Coords = {
  latitude: number;
  longitude: number;
}

export const HedgerowPlantTypes: string[] = [
  'Hawthorn',
  'Blackthorn',
  'Hazel',
  'Holly',
  'Beech',
  'Bird Cherry',
  'Dog Rose',
  'Wild Privet',
  'Crab Apple',
  'Guilder Rose',
  'WildCherry',
  'Plum'
]

export const HedgerowTreeTypes: string[] = [
  'Ash',
  'Common Oak',
  'Sessile Oak',
  'Elm',
  'Field Maple',
  'Aspen',
  'Chestnut',
]

export const HedgerowCutHeightUnit = {
  Centimeters: 'cm',
  Inches: 'in',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type HedgerowCutHeightUnit = typeof HedgerowCutHeightUnit[keyof typeof HedgerowCutHeightUnit];

export const HedgerowHeight = {
  Less1Metre: "0to1Metre",
  Between1And2Metres: "1to2Metre",
  Between2And3Metres: "2to3Metre",
  Between3And4Metres: "3to4Metre",
  Over4Metres: "3toInfiniteMetre",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type HedgerowHeight = typeof HedgerowHeight[keyof typeof HedgerowHeight];

export const getHedgerowHeightDescription = (height: HedgerowHeight): string => {
  switch (height) {
    case HedgerowHeight.Less1Metre:
      return "Less than 1 metre";
    case HedgerowHeight.Between1And2Metres:
      return "1 to 2 metres";
    case HedgerowHeight.Between2And3Metres:
      return "2 to 3 metres";
    case HedgerowHeight.Between3And4Metres:
      return "3 to 4 metres";
    case HedgerowHeight.Over4Metres:
      return "More than 4 metres";
    default:
      return "Unknown";
  }
}

export const HedgerowWidth = {
  Less1Metre: "0to1Metre",
  Between1And2Metres: "1to2Metre",
  Between2And3Metres: "2to3Metre",
  Over3Metres: "3toInfiniteMetre",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type HedgerowWidth = typeof HedgerowWidth[keyof typeof HedgerowWidth];

export const getHedgerowWidthDescription = (width: HedgerowWidth): string => {
  switch (width) {
    case HedgerowWidth.Less1Metre:
      return "Less than 1 metre";
    case HedgerowWidth.Between1And2Metres:
      return "1 to 2 metres";
    case HedgerowWidth.Between2And3Metres:
      return "2 to 3 metres";
    case HedgerowWidth.Over3Metres:
      return "More than 3 metres";
    default:
      return "Unknown";
  }
}

export const HedgerowAge = {
  Less10Years: "0to10Years",
  Between10And30Years: "10to30Years",
  Between30And50Years: "30to50Years",
  Over50Years: "50toInfiniteYears",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type HedgerowAge = typeof HedgerowAge[keyof typeof HedgerowAge];

export const getHedgerowAgeDescription = (width: HedgerowAge): string => {
  switch (width) {
    case HedgerowAge.Less10Years:
      return "Less than 10 years";
    case HedgerowAge.Between10And30Years:
      return "10 to 30 years";
    case HedgerowAge.Between30And50Years:
      return "30 to 50 years";
    case HedgerowAge.Over50Years:
      return "More than 50 years";
    default:
      return "Unknown";
  }
}

export const HedgerowCondition = {
  H1: "H1",
  H2: "H2",
  H3: "H3",
  H4a:"H4a",
  H4b:"H4b",
  H4c:"H4c",
  H5: "H5",
  H6: "H6",
  H7: "H7",
  H8: "H8",
  H9: "H9",
  H10: "H10",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type HedgerowCondition = typeof HedgerowCondition[keyof typeof HedgerowCondition];

export const getHedgerowConditionDescription = (condition: HedgerowCondition): string => {
  switch (condition) {
    case HedgerowCondition.H1:
      return "Over-trimmed (H1)";
    case HedgerowCondition.H2:
      return "Over-trimmed (H2)";
    case HedgerowCondition.H3:
      return "Over-trimmed (H3)";
    case HedgerowCondition.H4a:
      return "Rejuvenated - Recently laid (H4a)";
    case HedgerowCondition.H4b:
      return "Rejuvenated - Recently coppiced (H4b)";
    case HedgerowCondition.H4c:
      return "Rejuvenated - Recently planted (H4c)";
    case HedgerowCondition.H5:
      return "Dense and Managed (H5)";
    case HedgerowCondition.H6:
      return "Dense and Managed (H6)";
    case HedgerowCondition.H7:
      return "Dense and Managed (H7)";
    case HedgerowCondition.H8:
      return "Tall or overgrown (H8)";
    case HedgerowCondition.H9:
      return "Tall or overgrown (H9)";
    case HedgerowCondition.H10:
      return "Tall or overgrown (H10)";
    default:
      return "Unknown";
  }
}

export const HedgerowStyle = {
  Coppice: "Coppiced",
  SingleLaid: "SingleLaid",
  DoubleLaid: "DoubleLaid",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type HedgerowStyle = typeof HedgerowStyle[keyof typeof HedgerowStyle];

export const getHedgerowStyleDescription = (style: HedgerowStyle): string => {
  switch (style) {
    case HedgerowStyle.Coppice:
      return "Coppiced";
    case HedgerowStyle.SingleLaid:
      return "Single Laid";
    case HedgerowStyle.DoubleLaid:
      return "Double Laid";
    default:
      return "Unknown";
  }
}

export const HedgerowFertiliser = {
  Organic: "Organic",
  Chemical: "Chemical",
  None: "None",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type HedgerowFertiliser = typeof HedgerowFertiliser[keyof typeof HedgerowFertiliser];

export const getHedgerowFertiliserDescription = (fertiliser: HedgerowFertiliser): string => {
  switch (fertiliser) {
    case HedgerowFertiliser.Organic:
      return "Organic";
    case HedgerowFertiliser.Chemical:
      return "Chemical";
    case HedgerowFertiliser.None:
      return "None";
    default:
      return "Unknown";
  }
}
